<template>
  <div>
    <ejs-dialog
      ref="weekendReservationPopup"
      header="추첨예약 설정"
      :width="600"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="true"
      :animationSettings="{ effect: 'None' }"
      :close="onWeekendReservationPopupClosed"
    >
      <div class="window weekendReservationSetting">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">추첨예약 오픈기간 설정</div>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field creationDate">
                              <!-- 필수 : required -->
                              <div class="title">생성일자</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group dateRange">
                                    <div class="form">
                                      <input-date-range
                                        ref="reservationCreateRange"
                                        type="body-data"
                                        v-model="reservationCreateRange"
                                        v-on:keydown.native="preventEnterEvent"
                                      />
                                    </div>
                                  </li>
                                  <li class="item form-group button">
                                    <ul class="button">
                                      <li>
                                        <erp-button
                                          button-div="GET"
                                          v-on:click.native="
                                            settingStartEndDe(1)
                                          "
                                        >
                                          1개월
                                        </erp-button>
                                      </li>
                                      <li>
                                        <erp-button
                                          button-div="GET"
                                          v-on:click.native="
                                            settingStartEndDe(3)
                                          "
                                        >
                                          3개월
                                        </erp-button>
                                      </li>
                                      <li>
                                        <erp-button
                                          button-div="GET"
                                          v-on:click.native="
                                            settingStartEndDe(6)
                                          "
                                        >
                                          6개월
                                        </erp-button>
                                      </li>
                                      <li>
                                        <erp-button
                                          button-div="GET"
                                          v-on:click.native="
                                            settingStartEndDe(12)
                                          "
                                        >
                                          1년
                                        </erp-button>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field weekdaysWeekends">
                              <!-- 필수 : required -->
                              <div class="title">주중/주말</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-multiselect
                                        id="bsnCodeDropdown"
                                        ref="bsnCodeDropdown"
                                        v-model="updateWeekend.bsnCode"
                                        :dataSource="
                                          updateWeekendOptions.bsnCodeOptions
                                        "
                                        placeHolder="전체"
                                        :fields="
                                          updateWeekendOptions.bsnCodeField
                                        "
                                        :showSelectAll="true"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-02">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">추첨예약 일정 설정</div>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">신청 시작일자</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group number">
                                    <div class="form">
                                      <input-number
                                        ref="weekendRqstde"
                                        v-model="updateWeekend.weekendRqstde"
                                        :allowDot="false"
                                        :allowMinus="false"
                                        :displayComma="false"
                                        :propMaxLength="3"
                                      />
                                    </div>
                                  </li>
                                  <li class="item form-group text">주전</li>
                                  <li class="item form-group select">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="weekendRqstdw"
                                        v-model="updateWeekend.weekendRqstdw"
                                        :dataSource="
                                          updateWeekendOptions.dwCodeOptions
                                        "
                                        :allowFiltering="false"
                                        :fields="{
                                          text: 'comName',
                                          value: 'comCode',
                                        }"
                                        cssClass="body-data-dropdown"
                                      ></ejs-dropdownlist>
                                    </div>
                                  </li>
                                  <li class="item form-group time">
                                    <div class="form">
                                      <input-time
                                        ref="weekendRqsttm"
                                        format="HH:mm"
                                        v-model="updateWeekend.weekendRqsttm"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">신청 마감일자</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group number">
                                    <div class="form">
                                      <input-number
                                        ref="weekendReqstClosde"
                                        v-model="
                                          updateWeekend.weekendReqstClosde
                                        "
                                        :allowDot="false"
                                        :allowMinus="false"
                                        :displayComma="false"
                                        :propMaxLength="3"
                                      />
                                    </div>
                                  </li>
                                  <li class="item form-group text">주전</li>
                                  <li class="item form-group select">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="weekendReqstClosdw"
                                        v-model="
                                          updateWeekend.weekendReqstClosdw
                                        "
                                        :dataSource="
                                          updateWeekendOptions.dwCodeOptions
                                        "
                                        :allowFiltering="false"
                                        :fields="{
                                          text: 'comName',
                                          value: 'comCode',
                                        }"
                                        cssClass="body-data-dropdown"
                                      ></ejs-dropdownlist>
                                    </div>
                                  </li>
                                  <li class="item form-group time">
                                    <div class="form">
                                      <input-time
                                        ref="weekendReqstClostm"
                                        format="HH:mm"
                                        v-model="
                                          updateWeekend.weekendReqstClostm
                                        "
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">확정 통보일자</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group number">
                                    <div class="form">
                                      <input-number
                                        ref="weekendConfde"
                                        v-model="updateWeekend.weekendConfde"
                                        :allowDot="false"
                                        :allowMinus="false"
                                        :displayComma="false"
                                        :propMaxLength="3"
                                      />
                                    </div>
                                  </li>
                                  <li class="item form-group text">주전</li>
                                  <li class="item form-group select">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="weekendConfdw"
                                        v-model="updateWeekend.weekendConfdw"
                                        :dataSource="
                                          updateWeekendOptions.dwCodeOptions
                                        "
                                        :allowFiltering="false"
                                        :fields="{
                                          text: 'comName',
                                          value: 'comCode',
                                        }"
                                        cssClass="body-data-dropdown"
                                      ></ejs-dropdownlist>
                                    </div>
                                  </li>
                                  <li class="item form-group time">
                                    <div class="form">
                                      <input-time
                                        ref="weekendConftm"
                                        format="HH:mm"
                                        v-model="updateWeekend.weekendConftm"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button
                  buttonDiv="SAVE"
                  :is-shortcut-button="true"
                  :isIconCustom="true"
                  @click.native="saveWeekendReservationPopup"
              >
                확인
              </erp-button>
            </li>
            <li class="reset">
              <erp-button
                  buttonDiv="SAVE"
                  @click.native="initWeekendReservationPopup"
                  :isIconCustom="true"
              >
                초기화
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  buttonDiv="CLOSE"
                  @click.native="closeWeekendReservationPopup"
                  :isIconCustom="true"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import { MultiSelect, CheckBoxSelection } from "@syncfusion/ej2-dropdowns";
import moment from "moment";

import { getFormattedDate } from "@/utils/date";
import {
  commonCodesGetCommonCode,
  commonCodesGetStandardInfo,
} from "@/utils/commonCodes";
import {
  saveCalendarWeekendSetting,
  initCalendarWeekendSetting,
} from "@/api/calendar";
import InputDateRange from "@/components/common/datetime/InputDateRange";
import InputTime from "@/components/common/datetime/InputTime";
import InputNumber from "@/components/common/InputNumber";
import { validateFormRefs } from "@/utils/formUtil";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

MultiSelect.Inject(CheckBoxSelection);

export default {
  name: "WeekendReservationPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    InputNumber,
    InputTime,
    InputDateRange,
    ErpButton
  },
  data: function () {
    return {
      updateWeekend: {
        startDe: null,
        endDe: null,
        bsnCode: ["WEEKDAY", "WEEKEND"],
        weekendRqstde: commonCodesGetStandardInfo("weekendRqstde"),
        weekendRqstdw: commonCodesGetStandardInfo("weekendRqstdw"),
        weekendRqsttm: commonCodesGetStandardInfo("weekendRqsttm"),
        weekendReqstClosde: commonCodesGetStandardInfo("weekendReqstClosde"),
        weekendReqstClosdw: commonCodesGetStandardInfo("weekendReqstClosdw"),
        weekendReqstClostm: commonCodesGetStandardInfo("weekendReqstClostm"),
        weekendConfde: commonCodesGetStandardInfo("weekendConfde"),
        weekendConfdw: commonCodesGetStandardInfo("weekendConfdw"),
        weekendConftm: commonCodesGetStandardInfo("weekendConftm"),
      },
      updateWeekendOptions: {
        bsnCodeOptions: [],
        dwCodeOptions: [],
        bsnCodeField: { text: "comName", value: "comCode" },
      },
      validateRefListCommon: {
        reservationCreateRange: {
          custom: {
            method: () => {
              return this.$refs.reservationCreateRange.validate();
            },
            message: "main.validationMessage.invalidateDateRange",
          },
        },
        bsnCodeDropdown: {
          required: true,
        },
      },
      validateRefList: {
        weekendRqstde: { required: true },
        weekendRqstdw: { required: true },
        weekendRqsttm: { required: true },
        weekendReqstClosde: { required: true },
        weekendReqstClosdw: { required: true },
        weekendReqstClostm: {
          required: true,
          custom: {
            method: () => {
              const reservationDateTime = this.getWeekendReservationDateTime();
              const requestDateTimeMoment = moment(
                reservationDateTime.requestDateTime,
                "YYYY-MM-DD HH:mm:ss"
              );
              const endDateTimeMoment = moment(
                reservationDateTime.endDateTime,
                "YYYY-MM-DD HH:mm:ss"
              );
              return !requestDateTimeMoment.isAfter(endDateTimeMoment);
            },
            message: "main.validationMessage.openCloseDateConfirmTimeValidate",
          },
        },
        weekendConfde: { required: true },
        weekendConfdw: { required: true },
        weekendConftm: {
          required: true,
          custom: {
            method: () => {
              const reservationDateTime = this.getWeekendReservationDateTime();
              const endDateTimeMoment = moment(
                reservationDateTime.endDateTime,
                "YYYY-MM-DD HH:mm:ss"
              );
              const confirmDateTimeMoment = moment(
                reservationDateTime.confirmDateTime,
                "YYYY-MM-DD HH:mm:ss"
              );
              return !endDateTimeMoment.isAfter(confirmDateTimeMoment);
            },
            message: "main.validationMessage.openCloseDateConfirmTimeValidate",
          },
        },
      },
    };
  },
  computed: {
    reservationCreateRange: {
      get: function () {
        return {
          from: this.updateWeekend.startDe,
          to: this.updateWeekend.endDe,
        };
      },
      set: function (reservationCreateRange) {
        this.updateWeekend.startDe = reservationCreateRange.from;
        this.updateWeekend.endDe = reservationCreateRange.to;
      },
    },
  },
  created: function () {
    // Common Code 값 로딩
    this.updateWeekendOptions.bsnCodeOptions = [
      { comCode: "WEEKDAY", comName: "주중" },
      { comCode: "WEEKEND", comName: "주말" },
    ];
    this.updateWeekendOptions.dwCodeOptions = commonCodesGetCommonCode(
      "DW_CODE"
    );
  },
  mounted: function () {},
  methods: {
    validateFormRefs,
    async showWeekendReservationPopup(dateFrom, dateTo) {
      this.$refs.weekendReservationPopup.show();
      this.updateWeekend.startDe = dateFrom;
      if (dateTo) {
        this.updateWeekend.endDe = dateTo;
      } else {
        this.settingStartEndDe(3);
      }
      await this.$nextTick();
      setTimeout(() => this.$refs.reservationCreateRange?.focus(), 100);
    },
    closeWeekendReservationPopup: function () {
      this.$refs.weekendReservationPopup.hide();
    },
    settingStartEndDe: function (month) {
      if (!this.updateWeekend.startDe) {
        this.errorToast("시작 날짜를 입력해 주십시오");
      } else {
        const newEndDeDate = new Date(this.updateWeekend.startDe);
        newEndDeDate.setMonth(newEndDeDate.getMonth() + month);

        this.updateWeekend.endDe = getFormattedDate(newEndDeDate);
      }
    },
    async initWeekendReservationPopup() {
      if (!this.validateFormRefs(this.validateRefListCommon)) {
        return;
      }

      if (
        await this.confirm(
          this.updateWeekend.startDe +
            " ~ " +
            this.updateWeekend.endDe +
            " 추첨예약 오픈기간을 초기화하시겠습니까?"
        )
      ) {
        initCalendarWeekendSetting({
          startDe: this.updateWeekend.startDe,
          endDe: this.updateWeekend.endDe,
          bsnCode: this.updateWeekend.bsnCode,
        })
          .then(() => {
            this.$emit("popupClosed", { isReload: true });
            this.$refs.weekendReservationPopup.hide();
          })
          .catch((error) => {
            console.log("initCalendarWeekendSetting.err.===>", error);
          });
      }
    },
    async saveWeekendReservationPopup() {
      if (
        !this.validateFormRefs({
          ...this.validateRefListCommon,
          ...this.validateRefList,
        })
      ) {
        return;
      }

      const reservationDateTime = this.getWeekendReservationDateTime();

      if (
        await this.confirm(
          reservationDateTime.startDe +
            " ~ " +
            reservationDateTime.endDe +
            " 추첨예약 오픈기간을 설정하시겠습니까?"
        )
      ) {
        saveCalendarWeekendSetting(reservationDateTime)
          .then(() => {
            this.$emit("popupClosed", { isReload: true });
            this.$refs.weekendReservationPopup.hide();
          })
          .catch((error) => {
            console.log("saveCalendarWeekendSetting.err.===>", error);
          });
      }
    },
    getWeekendReservationDateTime() {
      const requestDateTime = moment(
        this.updateWeekend.startDe + " " + this.updateWeekend.weekendRqsttm,
        "YYYY-MM-DD HH:mm:ss"
      )
        .subtract(this.updateWeekend.weekendRqstde * 7, "days")
        .day(Number(this.updateWeekend.weekendRqstdw) - 1);
      const endDateTime = moment(
        this.updateWeekend.startDe +
          " " +
          this.updateWeekend.weekendReqstClostm,
        "YYYY-MM-DD HH:mm:ss"
      )
        .subtract(this.updateWeekend.weekendReqstClosde * 7, "days")
        .day(Number(this.updateWeekend.weekendReqstClosdw) - 1);
      const confirmDateTime = moment(
        this.updateWeekend.startDe + " " + this.updateWeekend.weekendConftm,
        "YYYY-MM-DD HH:mm:ss"
      )
        .subtract(this.updateWeekend.weekendConfde * 7, "days")
        .day(Number(this.updateWeekend.weekendConfdw) - 1);

      return {
        startDe: this.updateWeekend.startDe,
        endDe: this.updateWeekend.endDe,
        bsnCode: this.updateWeekend.bsnCode,
        requestDateTime: requestDateTime.format("YYYY-MM-DD HH:mm:ss"),
        endDateTime: endDateTime.format("YYYY-MM-DD HH:mm:ss"),
        confirmDateTime: confirmDateTime.format("YYYY-MM-DD HH:mm:ss"),
      };
    },
    onWeekendReservationPopupClosed: function () {
      this.$emit("popupClosed");
    },
    preventEnterEvent: function (event) {
      // TODO : mixin 또는 공통모듈로
      if (event.key === "Enter") {
        event.preventDefault();
      }
    },
  },
};
</script>
