<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field year">
            <div class="title">년도</div>
            <ul class="content">
              <li class="item">
                <input-number
                  v-model="searchConditions.bsnYear"
                  :allowDot="false"
                  :allowMinus="false"
                  :displayComma="false"
                  :propMaxLength="4"
                ></input-number>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
            button-div="GET"
            :ignore="isPopupOpened"
            :is-shortcut-button="true"
            v-on:click.native="searchCalendar"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">캘린더 목록</div>
              <div class="header-caption">[{{ count }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button lookup-condition" style="margin-right: 140px">
                <li class="field" style="margin-top:-3px">
                  <ul class="content">
                    <li class="item">
                      <ul class="check">
                        <li>
                          <label>
                            <input
                              type="checkbox"
                              v-model="isCheckedForWeatherDetail"
                              @change="onWeatherDetailCheckedChange"
                            />
                            <i></i>
                            <div class="label">날씨세부정보</div>
                          </label>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li class="save keyColor">
                  <erp-button
                    button-div="SAVE"
                    :ignore="isPopupOpened"
                    :is-shortcut-button="true"
                    :is-key-color="true"
                    @click.native="saveCalendar"
                  >
                    저장
                  </erp-button>
                </li>
                <li class="add">
                  <erp-button
                    button-div="SAVE"
                    :ignore="isPopupOpened"
                    :is-icon-custom="true"
                    :is-custom-shortcut-button="true"
                    shortcut-key="calendarRegistration.shortcuts.addCalendar"
                    :shortcut="{key: 'F3'}"
                    @click.native="addCalendarPopup"
                  >
                    추가
                  </erp-button>
                </li>
                <li class="delete">
                  <erp-button
                    button-div="DELETE"
                    :ignore="isPopupOpened"
                    :is-shortcut-button="true"
                    @click.native="deleteCalendar"
                  >
                    삭제
                  </erp-button>
                </li>
                <li class="reset">
                  <erp-button
                    button-div="GET"
                    @click.native="cancelCalendarGrid"
                  >
                    초기화
                  </erp-button>
                </li>
                <li class="settings">
                  <erp-button
                    button-div="SAVE"
                    :is-icon-custom="true"
                    @click.native="openHolidayPopup"
                  >
                    공휴일 설정
                  </erp-button>
                </li>
                <li class="settings">
                  <erp-button
                    button-div="SAVE"
                    :is-icon-custom="true"
                    @click.native="realtimeWaitPopup()"
                  >
                    실시간예약 설정
                  </erp-button>
                </li>
                <li class="settings">
                  <erp-button
                    button-div="SAVE"
                    :is-icon-custom="true"
                    @click.native="weekendReservationPopup()"
                  >
                    추첨예약 설정
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                    button-div="FILE"
                    @click.native="onClickExcel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
              <div class="header-switch">
                <div class="title">
                  멀티 편집모드
                </div>
                <div class="switch">
                  <ejs-switch
                    v-model="isModifyMode"
                    :checked="isModifyMode"
                    @change="onModifySwitchChanged"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="section-caption">
            <div class="caption-navigation">
              <ejs-tab
                :showCloseButton="false"
                heightAdjustMode="Auto"
                :selected="onCalendarMonthTabSelected"
                overflowMode="Popup"
                :selectedItem="calendarTabIndex"
              >
                <e-tabitems>
                  <e-tabitem
                    v-for="n in 12" :key="n"
                    :header="{ text: `${n}월` }"
                  />
                </e-tabitems>
              </ejs-tab>
            </div>
          </div>
          <div class="section-body">
            <article class="body-article">
              <!-- 아코디언 : accordion / 닫힘 : close -->
              <section class="article-section section-0101">
                <div class="section-body">
                  <ejs-grid-wrapper
                    ref="calendarGrid"
                    :provides="gridProvides"
                    id="calendarGrid"
                    :dataSource="calendarList"
                    :columns="calendarColumns"
                    :allowExcelExport="true"
                    :allowFiltering="!isModifyMode"
                    :allowSorting="!isModifyMode"
                    :isAutoSelectCell="!isModifyMode"
                    :enableVirtualization="false"
                    :validationRules="calendarGridValidationRules"
                    :selectionSettings="calendarGridSelectionSettings"
                    :editSettings="calendarGridEditSettings"
                    @headerCellInfo="calendarGridHeaderCellInfo"
                    @queryCellInfo="calendarGridCustomiseCell"
                    @cellEdit="calendarGridCellEdit"
                    @recordClick="calendarGridRecordClicked"
                    @cellSaved="calendarGridCellSaved"
                    @actionComplete="calendarGridActionComplete"
                    @cellSelected="calendarGridCellSelected"
                  />
                </div>
              </section>
            </article>
          </div>
        </section>
      </article>
    </div>
    <add-calendar-popup
      v-if="isAddCalendarPopupOpen"
      ref="addCalendarPopup"
      @popupClosed="onAddCalendarPopupClosed"
    />
    <holiday-popup
      v-if="isHolidayPopupOpen"
      ref="holidayPopup"
      @popupClosed="onHolidayPopupClosed"
    />
    <weekend-reservation-popup
      v-if="isWeekendReservationPopupOpen"
      ref="weekendReservationPopup"
      @popupClosed="onWeekendReservationPopupClosed"
    />
    <realtime-wait-reservation-popup
      v-if="isRealtimeWaitPopupOpen"
      ref="realtimeWaitPopup"
      @popupClosed="onRealtimeWaitPopupClosed"
    />
    <calendar-weather-popup
      v-if="isCalendarWeatherPopupOpen"
      ref="calendarWeatherPopup"
      @popupClosed="onCalendarWeatherPopupClosed"
    />
    <edit-multiple-columns-popup
      v-if="isEditMultipleColumnsPopupOpen"
      ref="editMultipleColumnsPopup"
      @popupConfirm="onEditMultipleColumnsPopupConfirm"
      @popupClosed="onEditMultipleColumnsPopupClose"
    />
  </div>
</template>

<style scoped>
body .appContent .lookup-condition .field.year .content .item {width: 90px;}
body .appContent .body-article .body-article .section-body {overflow: hidden;border: none;}
</style>

<script>
import Vue from 'vue';
import moment from 'moment';
import {
  Edit,
  Resize,
  ForeignKey,
  TimePickerEditCell,
  ExcelExport,
} from '@syncfusion/ej2-vue-grids';
import ejsGridWrapper from '@/components/common/EjsGridWrapper.vue';
import { numberWithCommas } from '@/utils/number';
import { formNotPassedRequiredCheck } from '@/utils/formUtil';
import {
  DATE_FORMAT_YYYY_MM_DD,
  getFormattedDate,
  getDayOfWeekCaption,
  getTodayNavigationDate,
  getDayColorValue,
} from '@/utils/date';
import {
  getCalendarList,
  saveCalendarList,
  deleteCalendar,
} from '@/api/calendar';
import {
  commonCodesGetCommonCode,
  commonCodesGetCodeDesc, commonCodesGetComName,
} from '@/utils/commonCodes';
import InputNumber from '@/components/common/InputNumber';
import editMultipleColumnsPopup from '@/views/common/EditMultipleColumnsPopup';
import addCalendarPopup from './popup/AddCalendarPopup';
import holidayPopup from './popup/HolidayPopup';
import weekendReservationPopup from './popup/WeekendReservationPopup';
import realtimeWaitReservationPopup from './popup/RealtimeWaitReservationPopup';
import CalendarWeatherPopup from '@/views/golf-reservation/popup/CalendarWeatherPopup';
import GridDateTimeEdit from '@/views/layout/components/js/GridDateTimeEdit';
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import routeViewMixin from '@/views/layout/mixin/routeViewMixin';
import ErpButton from "@/components/button/ErpButton.vue";

Edit.AddEditors({ timepicker: TimePickerEditCell });

export default {
  name: 'calendarRegistration',
  components: {
    ejsGridWrapper,
    InputNumber,
    editMultipleColumnsPopup,
    CalendarWeatherPopup,
    addCalendarPopup,
    holidayPopup,
    weekendReservationPopup,
    realtimeWaitReservationPopup,
    ErpButton,
  },
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  computed: {
    isPopupOpened() {
      return (
        this.isAddCalendarPopupOpen ||
        this.isHolidayPopupOpen ||
        this.isWeekendReservationPopupOpen ||
        this.isRealtimeWaitPopupOpen ||
        this.isCalendarWeatherPopupOpen ||
        this.isEditMultipleColumnsPopupOpen
      );
    },
    calendarTabIndex() {
      return this.searchConditions.bsnMt - 1;
    },
  },
  data() {
    return {
      dynamicColumnFields: ["webOpenDt", "webEndDt", "weekendReqstDt", "weekendEndDt", "weekendConfirmDt"],
      isCheckedForWeatherDetail: false,
      count: 0,
      calendarListLength: 0,
      gridProvides: [Edit, Resize, ForeignKey, ExcelExport],
      calendarColumns: [
        {
          field: 'bsnDate',
          headerText: '영업일자',
          isPrimaryKey: true,
          allowEditing: false,
          type: 'string',
          textAlign: 'center',
          minWidth: 16,
          width: 100,
        },
        {
          field: 'dwName',
          headerText: '요일',
          allowEditing: false,
          type: 'string',
          textAlign: 'center',
          minWidth: 16,
          width: 70,
        },
        {
          field: 'bsnCode',
          headerText: '영업구분',
          minWidth: 16,
          width: 90,
          editType: 'dropdownedit',
          allowEditing: true,
          isCommonCodeField: true,
          textAlign: 'center',
          groupCode: 'BSN_CODE',
          edit: { params: { sortOrder: 'none' } },
        },
        {
          field: 'offSeasonFlag',
          headerText: '비수기',
          minWidth: 16,
          width: 80,
          editType: 'booleanedit',
          type: 'boolean',
          displayAsCheckBox: true,
          textAlign: 'Center',
          allowEditing: true,
        },
        {
          field: 'hldyFlag',
          headerText: '공휴일',
          minWidth: 16,
          width: 80,
          editType: 'booleanedit',
          type: 'boolean',
          displayAsCheckBox: true,
          textAlign: 'Center',
          allowEditing: true,
        },
        {
          field: 'hldyName',
          headerText: '공휴일명',
          type: 'string',
          minWidth: 16,
          width: 120,
          allowEditing: true,
        },
        {
          field: 'tpInfo',
          headerText: '날씨',
          allowEditing: false,
          allowFiltering: false,
          type: 'string',
          minWidth: 16,
          width: 135,
        },
        {
          field: 'prpayPayUseFlag1',
          headerText: '1부선불',
          minWidth: 16,
          width: 80,
          editType: 'booleanedit',
          type: 'boolean',
          displayAsCheckBox: true,
          textAlign: 'Center',
          visible: true,
          allowEditing: true,
        },
        {
          field: 'prpayPayUseFlag2',
          headerText: '2부선불',
          minWidth: 16,
          width: 80,
          editType: 'booleanedit',
          type: 'boolean',
          displayAsCheckBox: true,
          textAlign: 'Center',
          visible: true,
          allowEditing: true,
        },
        {
          field: 'prpayPayUseFlag',
          headerText: '3부선불',
          minWidth: 16,
          width: 80,
          editType: 'booleanedit',
          type: 'boolean',
          displayAsCheckBox: true,
          textAlign: 'Center',
          visible: true,
          allowEditing: true,
        },
        {
          field: 'selfPrpayPayUseFlag',
          headerText: '셀프타임선불',
          minWidth: 16,
          width: 110,
          editType: 'booleanedit',
          type: 'boolean',
          displayAsCheckBox: true,
          textAlign: 'Center',
          visible: false,
          allowEditing: true,
        },
        {
          field: 'yyIw',
          headerText: '년주차',
          minWidth: 16,
          width: 80,
          type: 'number',
          isNumericType: true,
          textAlign: 'center',
          allowEditing: true,
        },
        {
          field: 'mtIw',
          headerText: '월주차',
          minWidth: 16,
          width: 80,
          type: 'number',
          isNumericType: true,
          textAlign: 'center',
          allowEditing: true,
        },
        {
          field: 'sunriseTime',
          headerText: '일출',
          minWidth: 16,
          width: 60,
          type: 'string',
          isTimeType: true,
          defaultValue: '00:00',
          textAlign: 'center',
          allowEditing: true,
        },
        {
          field: 'sunsetTime',
          headerText: '일몰',
          minWidth: 16,
          width: 60,
          type: 'string',
          isTimeType: true,
          defaultValue: '00:00',
          textAlign: 'center',
          allowEditing: true,
        },
        {
          field: 'twoPersonTeamCnt',
          headerText: '2인팀수',
          minWidth: 16,
          width: 90,
          type: 'number',
          textAlign: 'center',
          isNumericType: true,
          allowEditing: true,
        },
        {
          headerText: '실시간 오픈일정',
          columns: [
            {
              field: 'webOpenDt',
              headerText: '오픈일시',
              type: 'string',
              edit: new GridDateTimeEdit('webOpenDt', 'yyyy-MM-dd HH:mm'),
              minWidth: 16,
              width: 210,
              textAlign: 'Left',
              isDatetimeType: true,
              allowEditing: true,
              valueAccessor: (field, data) => {
                // Open 일시에 텍스트 추가
                const webOpenDt = moment(data.webOpenDt).format(`${DATE_FORMAT_YYYY_MM_DD} HH:mm`);
                const bsnDate = data['bsnDate'];
                if (webOpenDt) {
                  const bsnDateMoment = moment(
                    `${bsnDate}`,
                    DATE_FORMAT_YYYY_MM_DD
                  );
                  const webOpenDtMoment = moment(
                    webOpenDt.split(' ')[0],
                    `${DATE_FORMAT_YYYY_MM_DD}`
                  );

                  if (webOpenDtMoment.isValid()) {
                    const diff = bsnDateMoment.diff(webOpenDtMoment, 'days');

                    if (diff !== 0) {
                      return `${webOpenDt} ${diff} 일전 ${getDayOfWeekCaption(
                        webOpenDtMoment.toDate()
                      )}요일`;
                    } else {
                      return webOpenDt;
                    }
                  } else {
                    return null;
                  }
                } else {
                  return null;
                }
              },
            },
            {
              field: 'webEndDt',
              headerText: '종료일시',
              type: 'string',
              edit: new GridDateTimeEdit('webEndDt', 'yyyy-MM-dd HH:mm'),
              minWidth: 16,
              width: 180,
              textAlign: 'Left',
              isDatetimeType: true,
              allowEditing: true,
              valueAccessor: (field, data) => {
                if(moment(data.webEndDt).isValid()) {
                  return data.webEndDt && moment(data.webEndDt).format(`${DATE_FORMAT_YYYY_MM_DD} HH:mm`);
                }else {
                  return null;
                }
              },
            },
          ],
        },
        {
          headerText: '추첨예약 오픈일정',
          columns: [
            {
              field: 'weekendReqstDt',
              headerText: '오픈일시',
              type: 'string',
              edit: new GridDateTimeEdit('weekendReqstDt', 'yyyy-MM-dd HH:mm'),
              minWidth: 16,
              width: 180,
              textAlign: 'Left',
              isDatetimeType: true,
              allowEditing: true,
              valueAccessor: (field, data) => {
                return data.weekendReqstDt && moment(data.weekendReqstDt).format(`${DATE_FORMAT_YYYY_MM_DD} HH:mm`);
              },
            },
            {
              field: 'weekendEndDt',
              headerText: '종료일시',
              type: 'string',
              edit: new GridDateTimeEdit('weekendEndDt', 'yyyy-MM-dd HH:mm'),
              minWidth: 16,
              width: 180,
              textAlign: 'Left',
              isDatetimeType: true,
              allowEditing: true,
              valueAccessor: (field, data) => {
                return data.weekendEndDt && moment(data.weekendEndDt).format(`${DATE_FORMAT_YYYY_MM_DD} HH:mm`);
              },
            },
            {
              field: 'weekendConfirmDt',
              headerText: '확정일시',
              type: 'string',
              edit: new GridDateTimeEdit(
                'weekendConfirmDt',
                'yyyy-MM-dd HH:mm'
              ),
              minWidth: 16,
              width: 180,
              textAlign: 'Left',
              isDatetimeType: true,
              allowEditing: true,
              valueAccessor: (field, data) => {
                return data.weekendConfirmDt && moment(data.weekendConfirmDt).format(`${DATE_FORMAT_YYYY_MM_DD} HH:mm`);
              },
            },
          ],
        },
        {
          field: 'remarks',
          headerText: '비고',
          type: 'string',
          minWidth: 16,
          width: 240,
          maxLength: 400,
          isRemarks: true,
          allowEditing: true,
        },
        {
          field: 'insertName',
          headerText: '등록자',
          allowEditing: false,
          type: 'string',
          width: 110,
          textAlign: 'left',
        },
        {
          field: 'insertDt',
          headerText: '등록일시',
          allowEditing: false,
          type: 'string',
          width: 130,
          textAlign: 'center',
        },
        {
          field: 'updateName',
          headerText: '수정자',
          allowEditing: false,
          type: 'string',
          width: 110,
          textAlign: 'left',
        },
        {
          field: 'updateDt',
          headerText: '수정일시',
          allowEditing: false,
          type: 'string',
          width: 130,
          textAlign: 'center',
        },
      ],
      calendarGridValidationRules: {
        hldyName: {
          maxLength: 50,
          custom: {
            method: (args) => {
              if (args.hldyFlag) {
                return !formNotPassedRequiredCheck(args.hldyName);
              }
              return true;
            },
            message: 'main.validationMessage.requiredMessage',
          },
        },
        remarks: {
          maxLength: 400,
        },
      },
      calendarList: [],
      calendarListOptions: {
        bsnCodeOptions: [],
      },
      searchConditions: {
        bsnYear: null,
        bsnMt: null,
      },
      numberTemplate() {
        // TODO : 공통 모듈로??
        return {
          template: Vue.component('editOption', {
            template: '<span>{{parseInt(data.index) + 1}}</span>',
            data() {
              return {
                data: {
                  data: {},
                },
              };
            },
          }),
        };
      },
      calendarGridSelectionSettings: { mode: 'Both' },
      calendarGridEditSettings: {
        allowEditing: true,
        allowAdding: false,
        allowDeleting: false,
        mode: 'Batch',
        showConfirmDialog: false,
      },
      calendarItemMaxLengthRules: {
        maxLength: [50, '최대 50자까지 입력하실 수 있습니다'], // TODO : 추후 정리 및 공통모듈화 필요
      },
      calendarRemarksMaxLengthRules: {
        maxLength: [400, '최대 400자까지 입력하실 수 있습니다'], // TODO : 추후 정리 및 공통모듈화 필요
      },
      numericParams: {
        params: {
          // 편집 시 소숫점 이하 자릿수 제한을 위함. TODO : 공통모듈화
          validateDecimalOnType: true,
          decimals: 0,
          format: 'N',
        },
      },
      webOpenDtValue: null, // 오픈일시 timezone 제거를 위함
      webOpenDtEdit: {
        params: {
          change: (e) => {
            this.webOpenDtValue = e.element.value;
          },
        },
      },
      sampleBackgroundColor: [
        '#FFD8D8',
        '#FAE0D4',
        '#FAECC5',
        '#FAF4C0',
        '#E4F7BA',
        '#CEFBC9',
        '#D4F4FA',
        '#D9E5FF',
        '#DAD9FF',
        '#E8D9FF',
      ].sort(() => Math.random() - 0.5),
      isAddCalendarPopupOpen: false,
      isHolidayPopupOpen: false,
      isWeekendReservationPopupOpen: false,
      isRealtimeWaitPopupOpen: false,
      isCalendarWeatherPopupOpen: false,
      isEditMultipleColumnsPopupOpen: false,
      isModifyMode: false,
    };
  },
  async created() {
    const now = await getTodayNavigationDate(DATE_FORMAT_YYYY_MM_DD);

    const nowYear = new Date(now).getFullYear();
    const nowMonth = new Date(now).getMonth() + 1;

    const queryBsnYear = this.$route.query.bsnYear;
    const queryBsnMt = this.$route.query.bsnMt;

    // 올해, 현재 달 설정 - query 값이 있으면 그 값으로 설정
    if (
      queryBsnYear &&
      !isNaN(queryBsnYear) &&
      queryBsnMt &&
      !isNaN(queryBsnMt)
    ) {
      this.searchConditions.bsnYear = parseInt(queryBsnYear);
      this.searchConditions.bsnMt = parseInt(queryBsnMt);
    } else {
      this.searchConditions.bsnYear = nowYear;
      this.searchConditions.bsnMt = nowMonth;
    }

    this.calendarListOptions.bsnCodeOptions = commonCodesGetCommonCode(
      'BSN_CODE'
    );

    this.getCalendarListAPI();
  },
  methods: {
    numberWithCommas,
    onCalendarMonthTabSelected(args) {
      this.searchConditions.bsnMt = args.selectedIndex + 1;
      this.getCalendarListAPI();
    },
    calendarGridCellEdit() {
      /*
      if (args.columnName === 'webOpenDt') {
        console.log(args.value)
        this.webOpenDtValue = args.value
      }
      */
    },
    searchCalendar() {
      this.getCalendarListAPI();
      /*if (!this.searchConditions.bsnYear) {
        this.errorToast('년도를 입력해 주십시오');
      } else {
        this.getCalendarListAPI();
      }*/
    },
    saveCalendar() {
      if (!this.$refs.calendarGrid.validate()) {
        return;
      }

      let saveData = this.$refs.calendarGrid.getBatchChanges();
      if (saveData.changedRecords && saveData.changedRecords.length > 0) {
        saveData.changedRecords = saveData.changedRecords.map((calendar) =>
          this.getConvertedCalendar(calendar, 'YYYY-MM-DD HH:mm:ss')
        );
      }

      saveCalendarList(
        this.searchConditions.bsnYear,
        this.searchConditions.bsnMt,
        saveData
      )
        .then(() => {
          this.getCalendarListAPI();

          this.infoToast(this.$t('main.popupMessage.saved'));
        })
        .catch((error) => {
          console.log('saveCalendar.err.===>', error);
        });
    },
    addCalendarPopup() {
      this.isAddCalendarPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.addCalendarPopup.showCalendarPopup(
          this.searchConditions.bsnYear
        );
      });
    },
    openHolidayPopup() {
      this.isHolidayPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.holidayPopup.showHolidayPopup(this.searchConditions.bsnYear);
      });
    },
    async weekendReservationPopup(dateFrom = null, dateTo = null) {
      this.isWeekendReservationPopupOpen = true;
      await this.$nextTick();
      this.$refs.weekendReservationPopup.showWeekendReservationPopup(
        getFormattedDate(
          dateFrom ||
          new Date().setFullYear(
            this.searchConditions.bsnYear,
            this.searchConditions.bsnMt - 1,
            1
          )
        ),
        dateTo && getFormattedDate(dateTo)
      );
    },
    async realtimeWaitPopup(dateFrom = null, dateTo = null) {
      this.isRealtimeWaitPopupOpen = true;
      await this.$nextTick();
      this.$refs.realtimeWaitPopup.showRealtimeWaitReservationPopup(
        getFormattedDate(
          dateFrom ||
          new Date().setFullYear(
            this.searchConditions.bsnYear,
            this.searchConditions.bsnMt - 1,
            1
          )
        ),
        dateTo && getFormattedDate(dateTo)
      );
    },
    calendarWeatherPopup(bsnDate) {
      if (this.isPopupOpened) {
        return;
      }
      this.isCalendarWeatherPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.calendarWeatherPopup.showPopup(bsnDate);
      });
    },
    cancelCalendarGrid() {
      this.$refs.calendarGrid.batchCancel();
    },
    calendarGridCustomiseCell(args) {
      const {
        column: {field},
        cell,
        data,
      } = args;
      // 수정영역 셀 처리
      if (
        field === 'bsnCode' ||
        field === 'hldyFlag' ||
        field === 'hldyName' ||
        field === 'prpayPayUseFlag1' ||
        field === 'prpayPayUseFlag2' ||
        field === 'prpayPayUseFlag' ||
        field === 'yyIw' ||
        field === 'mtIw' ||
        field === 'sunriseTime' ||
        field === 'sunsetTime' ||
        field === 'webOpenDt' ||
        field === 'webEndDt' ||
        field === 'weekendReqstDt' ||
        field === 'weekendEndDt' ||
        field === 'weekendConfirmDt' ||
        field === 'remarks' ||
        field === 'offSeasonFlag' ||
        field === 'selfPrpayPayUseFlag' ||
        field === 'twoPersonTeamCnt'
      ) {
        cell.classList.add(this.$t('className.grid.modifyArea'));
      }
      if (
        field === 'webOpenDt' ||
        field === 'weekendReqstDt' ||
        field === 'remarks'
      ) {
        cell.classList.add(this.$t('className.grid.devVerticalLine'));
      }
      if (field === 'tpInfo') {
        const data = args.data;
        if (data.weatherDiv) {
          const weatherDiv = commonCodesGetCodeDesc(
            'WEATHER_CODE',
            data.weatherDiv
          );
          const weather = require(`../../assets/images/weather/${weatherDiv}`);
          const weatherHTML = weather
            ? `<img src='${weather}' alt='날씨 아이콘'>`
            : '';

          let manualValue = null;
          if(this.isCheckedForWeatherDetail) {
            manualValue = commonCodesGetComName("WEATHER_CODE", data.weatherDiv);
            cell.innerHTML = `<div class='weather'>${weatherHTML} ${commonCodesGetComName("WEATHER_CODE", data.weatherDiv)}</div>`;
          } else {
            manualValue = `${data.lwetTp} ℃ / ${data.topTp} ℃`;
            cell.innerHTML = `<div class='weather'>${weatherHTML} ${data.lwetTp} ℃ / ${data.topTp} ℃</div>`;
          }
          const findRecord = this.$refs.calendarGrid.getElement().ej2_instances[0].dataSource.find(d => d._rid === data._rid);
          if(findRecord) {
            findRecord.tpInfo = manualValue;
          }
        } else {
          if (!this.commonMixinIsButtonDisableByAuth('commonWeatherGet')) {
            cell.innerText = '기상 등록';
            cell.classList.add(this.$t('className.grid.reservArea'));
          }
        }
        if (!this.commonMixinIsButtonDisableByAuth('commonWeatherGet')) {
          cell.classList.add(this.$t('className.grid.clickArea'));
        }
      }
      if (field === 'dwName') {
        cell.style.color = getDayColorValue(data.dwCode, data.bsnCode);
      } else if (field === 'bsnCode') {
        // 영업구분
        if (data.bsnCode === 'WEEKEND') {
          // 빨간색 : 영업구분이 주말
          cell.style.color = '#FF0000';
        }
      }
      if (this.isModifyMode) {
        const allowedEditColumns = this.calendarColumns
          ?.filter(item => item.allowEditing)
          ?.map(item => item.field)
          ?.concat(['webOpenDt', 'webEndDt', 'weekendReqstDt', 'weekendEndDt', 'weekendConfirmDt']);
        if (!allowedEditColumns.includes(field)) {
          cell.style.backgroundColor = '#f9f9f9';
          cell.style.pointerEvents = 'none';
        }
      } else {
        if (field === 'webOpenDt' && data.webOpenDtLine) {
          cell.style.backgroundColor = this.sampleBackgroundColor[data.webOpenDtLine - 1];
        }
        if (field === 'weekendReqstDt' && data.weekendReqstDtLine) {
          cell.style.backgroundColor = this.sampleBackgroundColor[data.weekendReqstDtLine - 1];
        }
      }
    },
    onAddCalendarPopupClosed(event) {
      this.isAddCalendarPopupOpen = false;
      if (event && event.isReload === true) {
        this.getCalendarListAPI();
      }
    },
    onHolidayPopupClosed(event) {
      this.isHolidayPopupOpen = false;
      if (event && event.isReload === true) {
        this.getCalendarListAPI();
      }
    },
    onWeekendReservationPopupClosed(event) {
      this.isWeekendReservationPopupOpen = false;
      if (event && event.isReload === true) {
        this.getCalendarListAPI();
      }
    },
    onRealtimeWaitPopupClosed(event) {
      this.isRealtimeWaitPopupOpen = false;
      if (event && event.isReload === true) {
        this.getCalendarListAPI();
      }
    },
    onCalendarWeatherPopupClosed() {
      this.isCalendarWeatherPopupOpen = false;
      this.getCalendarListAPI();
    },
    onEditMultipleColumnsPopupConfirm(args) {
      const {
        field,
        value,
        rowIndexes,
      } = args;
      this.isEditMultipleColumnsPopupOpen = false;
      if (rowIndexes.length < 1) {
        return;
      }
      rowIndexes.forEach(index => {
        this.$refs.calendarGrid.updateCell(
          index,
          field,
          value,
        );
      });
    },
    onEditMultipleColumnsPopupClose() {
      this.isEditMultipleColumnsPopupOpen = false;
    },
    calendarGridHeaderCellInfo(args) {
      const {
        cell: {
          column: {
            field,
            headerText,
          },
        },
        node,
      } = args;
      if (field === 'bsnCode'
      || field === 'twoPersonTeamCnt') {
        // 헤더에 필수영역 표시 클래스 추가
        node.classList.add(this.$t('className.grid.requiredInputHeader'));
      } else if (
        field === 'tpInfo' &&
        !this.isModifyMode &&
        !this.commonMixinIsButtonDisableByAuth('commonWeatherGet')
      ) {
        node.classList.add(this.$t('className.grid.clickArea'));
      }
      if (
        headerText === '실시간 오픈일정' ||
        headerText === '추첨예약 오픈일정' ||
        field === 'webOpenDt' ||
        field === 'weekendReqstDt' ||
        field === 'remarks'
      ) {
        node.classList.add(this.$t('className.grid.devVerticalLine'));
      }
      if (this.isModifyMode && headerText !== 'NO') {
        const allowedEditColumns = this.calendarColumns
          ?.filter(item => item.allowEditing)
          ?.map(item => item.field)
          ?.concat(['webOpenDt', 'webEndDt', 'weekendReqstDt', 'weekendEndDt', 'weekendConfirmDt']);
        if (allowedEditColumns.includes(field)) {
          node.style.backgroundColor = 'rgb(237, 246, 250)';
          node.addEventListener('click', async () => await this.onGridHeaderClicked(args.cell.column), false);
        }
      }
    },
    async onGridHeaderClicked(column) {
      if (!column?.field) {
        return;
      }
      const gridRefs = this.$refs.calendarGrid;
      const selectedRowIndexes = [...new Array(gridRefs?.getBatchCurrentViewRecords().length).keys()];
      this.isEditMultipleColumnsPopupOpen = true;
      await this.$nextTick();
      this.$refs.editMultipleColumnsPopup.showPopup(
        column,
        selectedRowIndexes
      );
    },
    calendarGridRecordClicked(args) {
      if (
        !this.commonMixinIsButtonDisableByAuth('commonWeatherGet') &&
        args.column.field === 'tpInfo'
      ) {
        this.calendarWeatherPopup(args.rowData.bsnDate);
      }
    },
    calendarGridCellSaved(args) {
      if (
        args.columnName === 'hldyFlag' &&
        args.value === true &&
        (args.rowData.bsnCode === 'WEEKDAY' || args.rowData.bsnCode === 'CLOSE')
      ) {
        // 공휴일 체크 시, 영업구분이 주중(WEEKEDAY) 또는 '휴장(CLOSE) 이면 '주말'(WEEKEND)로 자동 변경
        this.$refs.calendarGrid.updateCell(
          this.$refs.calendarGrid.getRowIndexByPrimaryKey(args.rowData.bsnDate),
          'bsnCode',
          'WEEKEND'
        );
      }
    },
    getCalendarListAPI() {
      // 칼렌다 조회 API 호출
      getCalendarList(
        this.searchConditions.bsnYear,
        this.searchConditions.bsnMt
      )
        .then(response => {
          let webOpenDtLine = null;
          let webOpenDtColor = 1;
          let weekendReqstDtLine = null;
          let weekendReqstDtColor = 1;
          this.calendarListLength = this.calendarList.length;
          this.calendarList = response.value.calendarList.map(item => {
            this.getConvertedCalendar(item, 'YYYY-MM-DD HH:mm');
            const webOpenDt = moment(item.webOpenDt).format(DATE_FORMAT_YYYY_MM_DD);
            const weekendReqstDt = moment(item.weekendReqstDt).format(DATE_FORMAT_YYYY_MM_DD);
            if (webOpenDtLine !== webOpenDt) {
              if (webOpenDtLine) {
                webOpenDtColor = webOpenDtColor >= 10
                  ? 1
                  : webOpenDtColor + 1;
              }
              webOpenDtLine = webOpenDt;
            }
            if (item.webOpenDt) {
              item.webOpenDtLine = webOpenDtColor;
            }
            if (weekendReqstDtLine !== weekendReqstDt) {
              if (weekendReqstDtLine) {
                weekendReqstDtColor = weekendReqstDtColor >= 10
                    ? 1
                    : weekendReqstDtColor + 1;
              }
              weekendReqstDtLine = weekendReqstDt;
            }
            if (item.weekendReqstDt) {
              item.weekendReqstDtLine = weekendReqstDtColor;
            }
            return item;
          });
          this.calendarListLength = this.calendarList.length;
        })
        .catch((error) => {
          console.log('getCalendarList.err.===>', error);
        });
    },
    getConvertedCalendar(calendar, format) {
      const webOpenDtMoment = new moment(calendar.webOpenDt);
      const webEndDtMoment = new moment(calendar.webEndDt);
      const weekendReqstDtMoment = new moment(calendar.weekendReqstDt);
      const weekendEndDtMoment = new moment(calendar.weekendEndDt);
      const weekendConfirmDtMoment = new moment(calendar.weekendConfirmDt);

      return {
        ...calendar,
        webOpenDt: webOpenDtMoment.isValid()
          ? webOpenDtMoment.format(format)
          : null,
        webEndDt: webEndDtMoment.isValid()
          ? webEndDtMoment.format(format)
          : null,
        weekendReqstDt: weekendReqstDtMoment.isValid()
          ? weekendReqstDtMoment.format(format)
          : null,
        weekendEndDt: weekendEndDtMoment.isValid()
          ? weekendEndDtMoment.format(format)
          : null,
        weekendConfirmDt: weekendConfirmDtMoment.isValid()
          ? weekendConfirmDtMoment.format(format)
          : null,
      };
    },
    deleteCalendar: async function () {
      if (
        await this.confirm(
          this.searchConditions.bsnMt + '월 캘린더 자료를 삭제하시겠습니까?'
        )
      ) {
        deleteCalendar(
          this.searchConditions.bsnYear,
          this.searchConditions.bsnMt
        )
          .then(() => {
            this.getCalendarListAPI();
          })
          .catch((error) => {
            console.log('deleteCalendar.err.===>', error);
          });
      }
    },
    calendarGridActionComplete() {
      this.count = numberWithCommas(
        this.$refs.calendarGrid?.getGridBatchCount() || 0
      );
    },
    onClickExcel() {
      this.$refs.calendarGrid.excelExport();
    },
    onModifySwitchChanged(args) {
      const isChecked = args.checked;
      this.calendarGridSelectionSettings = isChecked
        ? {cellSelectionMode: 'Box', type: 'Multiple', mode: 'Cell'}
        : {mode: 'Both'};
      this.$refs.calendarGrid.refresh();
      if (isChecked) {
        this.infoToast('멀티 편집할 셀 하나와 여러 로우를 드래그하세요.');
      }
    },
    async calendarGridCellSelected(args) {
      if (!this.isModifyMode) {
        return;
      }
      const {
        cellIndex: {cellIndex},
        selectedRowCellIndex
      } = args;
      if (selectedRowCellIndex[0]?.cellIndexes.length > 1) {
        this.errorToast('편집할 셀 하나만 선택해주세요.');
        return;
      }
      let columns = [];
      this.calendarColumns
        ?.forEach(column => {
          if (column?.columns) {
            column.columns
              ?.forEach(c => columns.push(c));
          } else {
            columns.push(column);
          }
        });
      const allowedEditColumns = columns
        ?.filter(item => item.allowEditing)
        ?.map(item => item.field);
      const column = columns[cellIndex - 1];
      if (!allowedEditColumns.includes(column?.field)) {
        this.errorToast('편집할 수 있는 셀이 아닙니다.');
        return;
      }
      const selectedRowIndexes = selectedRowCellIndex.map(item => item.rowIndex);
      if (selectedRowIndexes.length < 1) {
        return;
      }
      this.isEditMultipleColumnsPopupOpen = true;
      await this.$nextTick();
      this.$refs.editMultipleColumnsPopup.showPopup(
        column,
        selectedRowIndexes
      );
    },
    onWeatherDetailCheckedChange() {
      const pushColumn = {
        headerText: '날씨정보',
        field: 'weatherDetail',
        allowEditing: false,
        columns: [
          {
            allowEditing: false,
            field: 'lwetTp',
            headerText: '최저온도',
            type: 'number',
            width: 80,
            textAlign: 'Center',
            valueAccessor: (field, data) => {
              if(data[field] !== null && data[field] !== undefined) {
                return `${data[field]}℃`;
              }
            }
          },
          {
            allowEditing: false,
            field: 'topTp',
            headerText: '최고온도',
            type: 'number',
            width: 80,
            textAlign: 'Center',
            valueAccessor: (field, data) => {
              if(data[field] !== null && data[field] !== undefined) {
                return `${data[field]}℃`;
              }
            }
          },
          {
            allowEditing: false,
            field: 'prcptQy',
            headerText: '강수량',
            type: 'number',
            width: 80,
            textAlign: 'Center',
            valueAccessor: (field, data) => {
              if(data[field]) {
                return `${data[field]}mm`;
              }
            }
          },
          {
            allowEditing: false,
            field: 'snowdQy',
            headerText: '적설량',
            type: 'number',
            width: 80,
            textAlign: 'Center',
            valueAccessor: (field, data) => {
              if(data[field]) {
                return `${data[field]}mm`;
              }
            }
          },
          {
            allowEditing: false,
            field: 'lwetHd',
            headerText: '최저습도',
            type: 'number',
            width: 80,
            textAlign: 'Center',
            valueAccessor: (field, data) => {
              if(data[field]) {
                return `${data[field]}%`;
              }
            }
          },
          {
            allowEditing: false,
            field: 'topHd',
            headerText: '최고습도',
            type: 'number',
            width: 80,
            textAlign: 'Center',
            valueAccessor: (field, data) => {
              if(data[field]) {
                return `${data[field]}%`;
              }
            }
          },
          {
            allowEditing: false,
            field: 'windDirectionDiv',
            headerText: '풍향구분',
            type: 'string',
            width: 110,
            textAlign: 'Center',
            valueAccessor: (field, data) => {
              if(data[field]) {
                return commonCodesGetComName("WIND_DIRECTION_DIV", data[field]);
              }
            }
          },
          {
            allowEditing: false,
            field: 'windSpeed',
            headerText: '풍속',
            type: 'number',
            width: 80,
            textAlign: 'Center',
            valueAccessor: (field, data) => {
              if(data[field]) {
                return `${data[field]}m/s`;
              }
            }
          },
          {
            allowEditing: false,
            field: 'weatherRemarks',
            headerText: '비고',
            type: 'number',
            width: 150,
            textAlign: 'Left',
          },
        ],
      };
      if(this.isCheckedForWeatherDetail) {
        this.$refs.calendarGrid.hideColumns(this.dynamicColumnFields, "field");
        this.addColumn(pushColumn, 'tpInfo');
        //tpInfo
      } else {
        this.$refs.calendarGrid.showColumns(this.dynamicColumnFields, "field");
        this.removeColumn(pushColumn);
      }
      this.$refs.calendarGrid.refreshColumns();
    },
    /**
     *
     * @param columns typeof [{}]
     * @param locateColFieldName typeof String
     */
    addColumn(column, locateColFieldName=null) {
      const targetColumns = this.$refs.calendarGrid.getElement().ej2_instances[0].columns;
      if(!locateColFieldName) {
        targetColumns.push(column);
      } else {
        const idx = targetColumns.find(col => col.field === locateColFieldName).index;
        targetColumns.splice(idx + 1, 0, column);
      }
    },
    removeColumn(column) {
      this.$refs.calendarGrid.getElement().ej2_instances[0].columns
          = this.$refs.calendarGrid.getElement().ej2_instances[0].columns.filter(col => col.field !== column.field);
    }
  },
};
</script>
