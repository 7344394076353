<template>
  <div>
    <ejs-dialog
      :animationSettings="{ effect: 'None' }"
      ref="createCalendarPopup"
      :header="`캘린더 생성`"
      :allowDragging="true"
      :showCloseIcon="true"
      width="700"
      :isModal="true"
      :close="onCalendarPopupClosed"
    >
      <div class="window createCalendar">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field year">
                    <div class="title">년도</div>
                    <ul class="content">
                      <li class="item">
                        <input-number
                          v-model="addCalendar.bsnYear"
                          :allowDot="false"
                          :allowMinus="false"
                          :displayComma="false"
                          :propMaxLength="4"
                          @change="onCalendarYearChange"
                        ></input-number>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">캘린더정보 생성</div>
                      <div class="header-caption">
                        [{{ addCalendar.bsnYear }} 년도]
                      </div>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-data body">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field">
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <!-- 포커스 : focus -->
                                    <ul class="check">
                                      <li
                                        v-show="isMonthCheckAllCheckboxShow()"
                                      >
                                        <label>
                                          <input
                                            type="checkbox"
                                            id="calMonth_all"
                                            v-model="
                                              addCalendarOption.bsnMtCheckboxAll
                                            "
                                            value="true"
                                            @change="calMonthAllCheck"
                                          />
                                          <i></i>
                                          <div class="label">전체</div>
                                        </label>
                                      </li>

                                      <li
                                        v-for="monthNum in [
                                          1,
                                          2,
                                          3,
                                          4,
                                          5,
                                          6,
                                          7,
                                          8,
                                          9,
                                          10,
                                          11,
                                          12,
                                        ]"
                                        :key="monthNum"
                                      >
                                        <label>
                                          <input
                                            type="checkbox"
                                            :id="`calMonth_${monthNum}`"
                                            v-model="addCalendar.bsnMtList"
                                            :value="monthNum"
                                            @change="calMonthCheck"
                                            :disabled="
                                              isMonthCheckboxDisable(monthNum)
                                            "
                                          />
                                          <i></i>
                                          <div class="label">
                                            {{ monthNum }}월
                                          </div>
                                        </label>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button
                  buttonDiv="SAVE"
                  :is-shortcut-button="true"
                  :isIconCustom="true"
                  @click.native="confirmAddCalendarPopup"
              >
                확인
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  buttonDiv="CLOSE"
                  :isIconCustom="true"
                  @click.native="closeAddCalendarPopup"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import { getAddCalendarInfo, addCalendar } from "@/api/calendar";
import InputNumber from "@/components/common/InputNumber";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
export default {
  name: "calendarAddPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    ErpButton,
    InputNumber,
  },
  data: function () {
    return {
      addCalendar: {
        bsnYear: null,
        bsnMtList: [],
      },
      addCalendarOption: {
        bsnMtCheckboxAll: false,
        bsnMtList: [], // API에서 조회한 데이터
      },
    };
  },
  created() {},
  methods: {
    async showCalendarPopup(bsnYear) {
      this.addCalendar.bsnYear = bsnYear;
      this.fetchAddCalendarInfo();
      this.$refs.createCalendarPopup.show();
    },
    calMonthAllCheck: function () {
      if (this.addCalendarOption.bsnMtCheckboxAll) {
        this.addCalendar.bsnMtList = [
          1,
          2,
          3,
          4,
          5,
          6,
          7,
          8,
          9,
          10,
          11,
          12,
        ].filter((year) => !this.addCalendarOption.bsnMtList.includes(year));
      } else {
        this.addCalendar.bsnMtList = [];
      }
    },
    calMonthCheck: function () {
      this.addCalendarOption.bsnMtCheckboxAll =
        this.addCalendar.bsnMtList.length ===
        12 - this.addCalendarOption.bsnMtList.length;
    },
    async confirmAddCalendarPopup() {
      if (
        !this.addCalendar.bsnMtList ||
        this.addCalendar.bsnMtList.length === 0
      ) {
        this.errorToast("생성할 개월을 선택해 주십시오");
      } else {
        if (
          await this.confirm(
            "선택하신 " +
              this.addCalendar.bsnMtList.sort((a, b) => a - b).join("월, ") +
              "월 캘린더 정보를 생성하시겠습니까?"
          )
        ) {
          addCalendar({
            bsnYear: this.addCalendar.bsnYear,
            bsnMtList: this.addCalendar.bsnMtList,
          })
            .then(() => {
              this.$emit("popupClosed", { isReload: true });
              this.$refs.createCalendarPopup.hide();
            })
            .catch((error) => {
              console.log("generateCalendarInfo.err.===>", error);
            });
        }
      }
    },
    closeAddCalendarPopup: function () {
      this.$refs.createCalendarPopup.hide();
    },
    onCalendarPopupClosed: function () {
      this.$emit("popupClosed");
    },
    isMonthCheckAllCheckboxShow() {
      return this.addCalendarOption.bsnMtList.length < 12;
    },
    isMonthCheckboxDisable(monthNum) {
      return this.addCalendarOption.bsnMtList.includes(monthNum);
    },
    async fetchAddCalendarInfo() {
      const {
        value: { calendarAdd },
      } = await getAddCalendarInfo(this.addCalendar.bsnYear);
      this.addCalendarOption.bsnMtCheckboxAll = false;
      this.addCalendarOption.bsnMtList = calendarAdd.bsnMtList;
      this.addCalendar.bsnMtList = [];
    },
    async onCalendarYearChange() {
      this.fetchAddCalendarInfo();
    },
  },
};
</script>
